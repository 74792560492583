import { ISurvey } from 'api/types'
import isEmpty from 'lodash/fp/isEmpty'
import groupBy from 'lodash/fp/groupBy'
import flow from 'lodash/fp/flow'
import uniqBy from 'lodash/fp/uniqBy'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import { SurveyStatusEnum } from 'types'
import { IDeclaration } from 'api/useDeclarations'

export function getSurveyMultipleChoices(data: ISurvey[]) {
  return data?.map(({ retailerName, id }: ISurvey) => ({
    text: retailerName,
    value: id,
  }))
}

export function prepareExlusionList(data) {
  const {
    soyaSurveys,
    soyaSupply,
    soyaReason,
    soyaReportingCompany,
    soyaAdditionalExclusionReason,
    palmOilSurveys,
    palmOilSupply,
    palmOilReason,
    palmOilReportingCompany,
    palmOilAdditionalExclusionReason,
    cocoaSupply,
    cocoaSurveys,
    cocoaReason,
    cocoaReportingCompany,
    cocoaAdditionalExclusionReason,
    coffeeSupply,
    coffeeSurveys,
    coffeeReason,
    coffeeReportingCompany,
    coffeeAdditionalExclusionReason,
  } = data
  let soyaExlusions = [],
    palmExlusions = [],
    coffeeExclusions = [],
    cocoaExlusions = []
  if (!isEmpty(soyaSurveys)) {
    soyaExlusions = soyaSurveys.map((surveyId: string) => ({
      surveyId,
      isSupplied: soyaSupply,
      reason: soyaReason,
      reportingCompanyDetails: soyaReportingCompany,
      additionalExclusionReason: soyaAdditionalExclusionReason,
    }))
  }
  if (!isEmpty(palmOilSurveys)) {
    palmExlusions = palmOilSurveys.map((surveyId: string) => ({
      surveyId,
      isSupplied: palmOilSupply,
      reason: palmOilReason,
      reportingCompanyDetails: palmOilReportingCompany,
      additionalExclusionReason: palmOilAdditionalExclusionReason,
    }))
  }
  if (!isEmpty(cocoaSurveys)) {
    cocoaExlusions = cocoaSurveys.map((surveyId: string) => ({
      surveyId,
      isSupplied: cocoaSupply,
      reason: cocoaReason,
      reportingCompanyDetails: cocoaReportingCompany,
      additionalExclusionReason: cocoaAdditionalExclusionReason,
    }))
  }
  if (!isEmpty(coffeeSurveys)) {
    coffeeExclusions = coffeeSurveys.map((surveyId: string) => ({
      surveyId,
      isSupplied: coffeeSupply,
      reason: coffeeReason,
      reportingCompanyDetails: coffeeReportingCompany,
      additionalExclusionReason: coffeeAdditionalExclusionReason,
    }))
  }

  return [...soyaExlusions, ...palmExlusions, ...cocoaExlusions, ...coffeeExclusions]
}

export function getReadyToValidateSurveys(surveys) {
  return flow([filter((item: ISurvey) => item.status === SurveyStatusEnum.Sent), groupBy('type')])(
    surveys,
  )
}

export function getDeclarationSurveys(surveys) {
  return flow([uniqBy('type'), map('type')])(surveys)
}

export function getDeclarationSurveysForAdmin(declarations: IDeclaration[]) {
  return flow([
    uniqBy('type'),
    map('type'),
  ])(declarations)
}
